import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";

// Import styles
import "./styles/contact-us.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Icons
import { FaEnvelope } from "react-icons/fa";

// Bootstrap imports
import { Button, Col, Container, Row } from "react-bootstrap";

const ContactUsPage = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Contact Us" }) {
        id
        contactUsFields {
          backgroundImage {
            altText
            localFile {
              url
            }
          }
        }
        seoFields {
          seo {
            title
            description
          }
        }
      }
    }
  `);

  // Wordpress data
  const { wpPage } = data;

  // Background image
  let backgroundImage = {
    backgroundImage: `url(${wpPage.contactUsFields.backgroundImage.localFile.url})`,
  };

  return (
    <Layout>
      <MetaData
        title={wpPage.seoFields.seo.title}
        description={wpPage.seoFields.seo.description}
        image={`https://mountaineer-dental.netlify.app${image}`}
      />
      <div className="contact-us-page">
        <div
          className="contact-us-intro fade-in-slow"
          style={backgroundImage}
        />
        <div className="overlay" />
        <div className="form-container">
          <div className="background-shape" />
          <Container className="fade-in-slow">
            <h2 className="shout">
              <FaEnvelope />
              Contact Us
            </h2>
            <form
              id="newPatients"
              name="Contact Form"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <Row>
                <Col xs={12}>
                  <h3 className="yell">Request An Appointment:</h3>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="text"
                      name="Name"
                      placeHolder="Enter your name."
                    />
                  </label>
                </Col>
                <Col xs={12} md={6}>
                  <label>
                    <input
                      type="email"
                      name="Email"
                      placeHolder="Enter your email."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <input
                      type="text"
                      name="Subject"
                      placeHolder="Enter the subject of your message."
                    />
                  </label>
                </Col>
                <Col xs={12}>
                  <label>
                    <textarea
                      name="Message"
                      placeHolder="Type us a message."
                    ></textarea>
                  </label>
                </Col>
                <Col xs={12}>
                  <button className="btn btn-accent" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
